<template>
  <header v-if="currentRouteName ==='home'">
    <div class="flex justify-center">
      <router-link to="/"
        ><img
          class="sm:ml-5 w-48 my-6 sm:m-0 sm:w-40 lg:w-48"
          src="@/assets/images/logo.png"
      /></router-link>
    </div>
    <div class="m-auto mb-6 sm:m-0 hidden md:block">
      <router-link
        to="/before"
        class="sm:mr-10 text-[#C76862] font-medium text-lg"
        >ACCÉDER AU FORMULAIRE</router-link
      >
    </div>
  </header>
</template>

<
<style lang="scss">
header {
  @apply w-full text-center sm:flex sm:flex-wrap sm:justify-between sm:items-center sm:h-24 max-w-[1920px] m-auto;
}
</style>
<script>
export default {
  name: "Header",
  computed: {
    currentRouteName() {
      return this.$route.name;
    },
  },
};
</script>